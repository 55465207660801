<template>
  <AnimatedBackground/>
  <div id="foreground">
    <MainMenu></MainMenu>
    <main>
      <aside>
        <ContentIndex></ContentIndex>
      </aside>
      <div id="content">
        <router-view/>
      </div>
    </main>
  </div>
</template>

<script>
import AnimatedBackground from './components/AnimatedBackground.vue'
import MainMenu from './components/MainMenu.vue'
import ContentIndex from './components/ContentIndex.vue'

export default {
  components: {
    AnimatedBackground, MainMenu, ContentIndex
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&family=Ubuntu:wght@300&display=swap');

body {
  font-family: 'Ubuntu', sans-serif;
  color: white;
  padding: 0;
  margin: 0;
}

#foreground {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

main {
  box-sizing: border-box;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

aside {
  box-sizing: border-box;
  padding: 10px;
  background-color: rgba(25, 25, 25, .6);
}

#content {
  box-sizing: border-box;
  padding: 30px;
  background-color: rgba(0, 0, 0, .75);
  flex-grow: 1;
  overflow: auto;
}

@media screen and (min-width: 700px) {
  main {
    flex-direction: row;
  }

  main aside {
    flex-grow: 0;
  }

  #content {
    padding-left: 25%;
    padding-right: 25%;
  }

  aside {
    min-width: 10vw !important;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #ff00b7 rgba(25, 25, 25, .6);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: rgba(25, 25, 25, .6);
}

*::-webkit-scrollbar-thumb {
  background-color: #ff00b7;
  border: none;
  border-radius: 5px;
}

/* content formatting */

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h2, h3, h4, h5, h6 {
  margin-top: 50px;
}

.justified {
  text-align: justify;
}

.centered {
  text-align: center;
}

@keyframes pulse {
  from {
    border-color: #ff00b7;
    color: #ff00b7;
  }
  to {
    border-color: white;
    color: white;
  }
}

a, a:visited {
  text-decoration: none;
  color: #ff00b7;
}

a:active {
  border-color: white;
  color: white;
}

a:hover {
  animation-name: pulse;
  animation-duration: .25s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.button {
  border: 1px solid #ff00b7;
  display: inline-block;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}

a.docs:has(img) {
  display: inline-block;
  border: 1px solid #ff00b7;
  border-radius: 5px;
  overflow: hidden;
}

a.docs img {
  max-width: 100%;
}

em {
  color: #bbb;
}

</style>
