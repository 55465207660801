<template>
  <nav>
    <li><router-link active-class="active" to="/">Intro</router-link></li>
    <li><router-link active-class="active" to="/prepare">Prepare</router-link></li>
    <li><router-link active-class="active" to="/build">Build</router-link></li>
    <li><router-link active-class="active" to="/make-interactive">Make Interactive</router-link></li>
    <li><router-link active-class="active" to="/submit">Submit</router-link></li>
  </nav>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

aside nav a, aside nav a:visited {
  font-family: 'Ubuntu Condensed', sans-serif;
}

aside nav {
  list-style-type: '• ';
}

aside li:has(a.active) {
  list-style-type: '⇨ ';
}

@media screen and (max-width: 700px) {
  aside li {
    display: inline-block;
    box-sizing: border-box;
    margin: 5px;
  }
}
</style>
