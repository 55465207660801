<template>
  <menu>
    <a id="logo" href="#/">
      <img src="../assets/LogoLong.svg" alt="LIBERVERSE CreationKit">
    </a>
    <nav>
      <li id="support"><a href="https://liberverse.net/dis" target="_blank" rel="noopener">Ask Question</a></li>
      <li><a href="https://liberverse.net" target="_blank" rel="noopener">LIBERVERSE</a></li>
      <li><a href="https://liberland.org" target="_blank" rel="noopener">Liberland</a></li>
    </nav>
  </menu>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  width: 100vw;
  background-color: rgba(25, 25, 25, .6);
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

menu img {
  height: 40px;
  margin-left: 5px;
}

#logo {
  flex-grow: 1;
}

menu nav {
  display: flex;
  flex-direction: row;
}

menu nav li {
  display: inline-block;
  margin-right: 15px;
}

menu a, menu a:visited {
  color: white;
  text-decoration: none;
  font-family: 'Ubuntu Condensed', sans-serif;
}

menu a:hover, menu a:active {
  color: #ff00b7;
  text-decoration: none;
  font-family: 'Ubuntu Condensed', sans-serif;
}

@media screen and (max-width: 700px) {
  menu nav li {
    display: none;
  }

  #support {
    display: inline-block;
  }
}

#support, #support:visited {
  color: #fade82;
}

</style>
