<template>
  <a :class="'button tip' + (type ? ' '+type : '')" @click="expanded = !expanded" :style="{ opacity: expanded ? '100%' : '50%' }">
    <h3 class="centered">{{ label }}{{ title ? ': '+title : '' }}</h3>
    <slot v-if="expanded"></slot>
  </a>
</template>

<script>

export default {
  props: {
    type: String,
    title: String,
    content: String,
  },
  data () {
    return {
      expanded: false
    }
  },
  computed: {
    label () {
      switch (this.type) {
        case 'pros': return 'Experienced Developers'
        case 'curiosity': return 'Curiosity'
        default: return 'Tip'
      }
    }
  }
}

</script>

<style>

a.tip, a.tip:hover, a.tip:visited, a.tip:active {
  display: block;
  margin: 0;
  margin-left: 40px;
  margin-right: 40px;
  color: rgba(255, 165, 0);
  border-color: rgba(255, 165, 0);
  background-color: rgba(255, 165, 0, .25);
  animation: none;
  cursor: pointer;
}

a.tip:hover {
  opacity: 100% !important;
}

a.tip h3 {
  margin: 0;
  font-size: 16px;
  font-weight: normal;
}

a.tip p {
  color: white;
  margin-left: 15px;
  margin-right: 15px;
}

a.tip.curiosity, a.tip.curiosity:hover, a.tip.curiosity:visited, a.tip.curiosity:active {
  color: rgb(0, 102, 255);
  border-color: rgb(0, 102, 255);
  background-color: rgba(0, 102, 255, .25);
}

a.tip.pros, a.tip.pros:hover, a.tip.pros:visited, a.tip.pros:active {
  color: rgb(195, 0, 255);
  border-color: rgb(195, 0, 255);
  background-color: rgba(195, 0, 255, .25);
}

</style>