<template>
  <p class="centered">
    <a class="docs" :href="src" target="_blank">
      <img :src="src" alt="" />
    </a>
  </p>
</template>

<script>

export default {
  props: {
    src: String
  }
}

</script>