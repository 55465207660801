import { createApp } from 'vue'
import App from './App.vue'
import Particles from 'particles.vue3'
import { createRouter, createWebHashHistory } from 'vue-router'
import ContentImage from '@/components/ContentImage.vue'
import ContentTip from '@/components/ContentTip.vue'


const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHashHistory(),
  routes: [
    { path: '/', component: () => import('./screens/ScreenIntro.vue') },
    { path: '/prepare', component: () => import('./screens/ScreenPreparing.vue') },
    { path: '/build', component: () => import('./screens/ScreenBuilding.vue') },
    { path: '/make-interactive', component: () => import('./screens/ScreenMakingInteractive.vue') },
    { path: '/submit', component: () => import('./screens/ScreenSubmitting.vue') },
  ],
})

router.afterEach(() => {
  console.log('Scrolling...')
  let myDiv = document.getElementById('content')
  myDiv.scrollTop = 0
})

const app = createApp(App)
app.config.globalProperties.$ueVersion = '5.2.0'

app.use(Particles)
app.use(router)
app.component('ContentImage', ContentImage)
app.component('ContentTip', ContentTip)
app.mount('#app')
